<template>
  <div id="app">
    <header-block></header-block>
    <router-view class="v-content"/>
    <footer-block></footer-block>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style lang="scss">
  @import "./assets/style.css";
  .v-content {
    padding: 0 50px;
  }
  @media (max-width: 450px) {
    .v-content {
      padding: 0 20px;
    }
  }
</style>
